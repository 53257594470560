.App {
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.GridBoxCell {
  border: 1px solid black;
}

.RightButton {
  float: right;
}

.ant-input-outlined {
  &:hover,
  &:focus {
    border-color: #019ed9;
  }
}

.MuiDataGrid-main {
  .MuiDataGrid-row {
    &:nth-child(odd) {
      background-color: rgba(0, 0, 0, 0.015);
    }
  }
}

.ant-btn-primary {
  background-color: #ffffff !important;

  &:hover,
  &:focus {
    color: #049ed7;
    background-color: #ecf9ff !important;
  }
}

.ant-tree {
  .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: #dadfff;
  }
}

.login-cta {
  &.ant-btn-default {
    &.ant-btn-background-ghost {
      background-color: #00aeef;
      background: #00aeef;

      &:not(:disabled) {
        &:not(.ant-btn-disabled) {
          &:hover,
          &:focus {
            background-color: #049ed7;
            background: #049ed7;
          }
        }
      }
    }
  }
}
.ant-input-group-addon {
  background-color: white;
}
.adaptify-search-field {
  .ant-btn {
      background-color: white;
  }
}

.ant-menu-light {
  &.ant-menu-horizontal {
    .ant-menu-item-selected {
      &:after {
        border-bottom-color: #019ed9;
      }
    }
  }
}

.login-wrapper {
  background: #f2f7fe;

  .login {
    background: url(../public/login-top.svg) no-repeat;
    background-position: top;
    background-size: contain;
  }
}

.login-card {
  border: solid 1px #a0e0f4;
  border-radius: 20px;
  padding: 50px;

  .ant-card-head {
    padding: 0;
    border: none;
    .ant-card-head-wrapper {
      .ant-card-head-title {
        font-size: 1.875rem;
        line-height: 1.23;
        font-weight: 500;
      }
    }
    + .ant-card-body {
      margin-top: 34px;
    }
  }
  .ant-card-body {
    padding: 0;

    .ant-form {
      .ant-form-item {
        label {
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.17;
        }

        .ant-form-item-control {
          .ant-form-item-control-input {
            .ant-form-item-control-input-content {
              min-height: 50px;
              .ant-input {
                min-height: 50px;
              }
              .ant-input-password {
                .ant-input {
                  min-height: 34px;
                }
              }
            }
          }
          .ant-form-item-explain {
            padding-top: 4px;
            .ant-form-item-explain-error {
              font-size: 0.75rem;
              line-height: 1;
            }
          }
        }
      }
    }
  }
}
