@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;

  font-family:
    'Roboto',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2.ant-typography {
  color: white;
}

.page-title {
  font-size: large;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 10px;
}

.page-title-nav {
  font-size: medium;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 10px;
}

.adaptify-modal .ant-modal-title {
  text-align: center;
}

.adaptify-layout .ant-layout-header {
  padding-left: 0px;
}

.adaptify-read-only-field .ant-select-selection-item, .ant-select-selection-item-content {
  background: #f0f0f0;
  color: black;
  cursor: not-allowed;
}

.adaptify-read-only-field,
.adaptify-read-only-field:focus,
.adaptify-read-only-field:hover {
  background: #f0f0f0;
  color: black;
  cursor: not-allowed;
  border-color: #acb7c6;
}

.adaptify-label-column {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ag-root-wrapper.ag-layout-normal {
  border: none;
}

.MuiDataGrid-cell {
  a {
    color: #019ed9;
  }
}

.adaptify-modal {
  .ant-modal-title {
    // text-align: center;
  }

  .ant-modal-content {
    padding: 3.125rem;

    .ant-modal-header {
      margin-bottom: 2.125rem;
    }
  }
}

.markdown-container {
  table {
    border-spacing: 0;
    border-collapse: collapse;
    border-color: inherit;
    display: block;
    margin: 0 auto;
    padding: 0.5em;
    width: 100%;
    max-width: 100%;
    overflow: auto;
  }
  tbody, td, tfoot, th, thead, tr {
      border-color: inherit;
      border-style: solid;
      border-width: 2px;
      padding: 0.5rem;
  }
}